import $ from "jquery";

let map;

var googleMapMarkerTitle = 'Vorbuchner GmbH';
var googleMapMarkerLatitude = '48.1037186'; //48.1037186,12.6435663
var googleMapMarkerLongitude = '12.6435663';
var googleMapLatitude = '48.1037186';
var googleMapLongitude = '12.6435663';

window.initMap = function() {
    
    map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 48.1037186, lng: 12.6435663 }, //48.2552684,12.4165312
        zoom: 13,
        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles: [
            { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [ { "color": "#444444" } ] },
            { "featureType": "landscape", "elementType": "all", "stylers": [ { "color": "#e5ecf0" } ] },
            { "featureType": "poi", "elementType": "all", "stylers": [ { "visibility": "off" } ] },
            { "featureType": "road", "elementType": "all", "stylers": [ { "saturation": -100 }, { "lightness": 45 } ] },
            { "featureType": "road.highway", "elementType": "all", "stylers": [ { "visibility": "simplified" } ] },
            { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [ { "visibility": "on" } ] },
            { "featureType": "transit", "elementType": "all", "stylers": [ { "visibility": "off" } ] },
            { "featureType": "water", "elementType": "all", "stylers": [ { "color": "#022F48" }, { "visibility": "on" }, { "saturation": "0" } ] }
        ]
    });   

    //set Marker
    var mapicon = {
        url: "/dist/images/map-icon.svg", // url
        scaledSize: new google.maps.Size(50,50), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(25, 25) // anchor
    };
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(googleMapMarkerLatitude,googleMapMarkerLongitude),
        map: map,
        title: googleMapMarkerTitle,
        icon : mapicon
    });
}